<template>
  <div class="Client font">
    <!-- BEGIN: Content-->

    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="ADMIN APPROVE"
          :breadcrumb="[
            {
              label: 'Kyc Approve',
            
            },
            { label: 'Kyc Approve' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <h4><b style="color: #00364f; font-weight: bolder">Admin</b></h4>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm font text">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Name</th>
                          <th class="text-truncate">Email</th>
                          <th class="text-truncate">Mobile</th>
                          <th class="text-truncate text-center">Status</th>
                          <th class="text-truncate text-end">Action</th>
                        </tr>
                        <tbody style="font-size: 14px">
                          <tr v-for="(adminInfo, index) in adminkyc" :key="index">
                            <td>
                              <span v-if="adminInfo.admin">
                                <img
                                  style="width: 30px; height: 30px; border-radius: 2px"
                                  :src="adminlogofinder(adminInfo.admin)"
                                  alt=""
                                />
                              </span>
                            </td>
                            <td>
                              <span v-if="adminInfo.admin">
                                {{ adminInfo.admin.code }}
                              </span>
                            </td>
                            <td>
                              <span v-if="adminInfo.admin">
                                {{ adminInfo.admin.name }}
                              </span>
                            </td>
                            <td>
                              <span v-if="adminInfo.admin">
                                {{ adminInfo.admin.email }}
                              </span>
                            </td>
                            <td>
                              <span v-if="adminInfo.admin">
                                {{ adminInfo.admin.phone }}
                              </span>
                            </td>
                            <td class="text-center">
                              <p
                                v-if="adminInfo.kycStatus == 'approve'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ adminInfo.kycStatus }}</b>
                              </p>
                              <p
                                v-if="adminInfo.kycStatus == 'reject'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ adminInfo.kycStatus }}</b>
                              </p>
                              <p
                                v-if="adminInfo.kycStatus == 'cancel'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: red;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ adminInfo.kycStatus }}</b>
                              </p>
                            </td>
                            <td class="text-end">
                              <button
                                @click="adminkycValueApprove(adminInfo)"
                                style="
                                  background-color: #1426f5;
                                  padding: 5px 4.5px 5px 4.5px;
                                "
                                class="btn btn-sm text-white"
                                data-bs-toggle="modal"
                                data-bs-target="#admin"
                              >
                                <font-awesome-icon icon="tag" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="admin"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <b>Approve Admin Kyc </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="coiPreImg"
                      :src="coiPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="coi-img">COI &nbsp;&nbsp;</b>
                    <span v-if="approveMyAdmin">{{ approveMyAdmin.cin }}</span>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionCoi == 'pdf'
                          ? openWindow(coiPreImgPdf)
                          : openWindow(coiPreImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="tdsPreImg"
                      :src="tdsPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="tds-photo">TDS &nbsp;&nbsp;</b>
                    <span v-if="approveMyAdmin">{{ approveMyAdmin.tds }}</span>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionTds == 'pdf'
                          ? openWindow(tdsPreImgPdf)
                          : openWindow(tdsPreImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="gstinPreImg"
                      :src="gstinPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="gstin-photo">GSTIN &nbsp;&nbsp;</b>
                    <span v-if="approveMyAdmin">{{ approveMyAdmin.gstin }}</span>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionGstIn == 'pdf'
                          ? openWindow(GstInPreImgPdf)
                          : openWindow(gstinPreImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="businessPanPreImg"
                      :src="businessPanPreImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="businessPan-photo">Business Pan &nbsp;&nbsp;</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionBusinessPan == 'pdf'
                          ? openWindow(businessPanPreImgPdf)
                          : openWindow(businessPanPreImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Admin Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                    >
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option selected value="cancel">Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button
                      @click="
                        extensionkycAdharFront == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Admin Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Admin Pan No &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option selected value="cancel">Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Admin Kyc</b>
                    <select v-model="approveKyc.status" class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel">Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      type="button"
                      @click="adminKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "AdminKycApprove",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      gstinPreImg: this.$store.state.placeholderImg,
      tdsPreImg: this.$store.state.placeholderImg,
      cinPreImg: this.$store.state.placeholderImg,
      coiPreImg: this.$store.state.placeholderImg,
      bankStatementPreImg: this.$store.state.placeholderImg,
      businessPanPreImg: this.$store.state.placeholderImg,

      loading: false,
      adharFrontImg: this.$store.state.placeholderImg,
      adharFrontImgPdf: "",
      adharBackImg: this.$store.state.placeholderImg,
      adharBackImgPdf: "",
      panImg: this.$store.state.placeholderImg,
      panImgPdf: "",
      passBookImg: this.$store.state.placeholderImg,
      approveMyAdmin: {},
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",
      adminkyc: [],
      approveKyc: new Form({
        admin_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),

      aadharapproveKyc: new Form({
        admin_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        admin_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        admin_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),
      assign_form: {
        client_service_id: null,
        admin_id: null,
      },
    };
  },
  methods: {
    openWindow(url) {
      window.open(`${url}`);
    },
    aadharKycApprovedSave() {
      this.$axios
        .post("staff/approveadminkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Aaddhar Kyc " + this.aadharapproveKyc.status + " Succesfully ", {
            autoClose: 1000,
          });
          this.loadadminKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    accountApproveKycSave() {
      this.$axios
        .post("staff/approveadminkyc", this.accountapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success(
            "Account Kyc " + this.accountapproveKyc.status + " Succesfully ",
            {
              autoClose: 1000,
            }
          );
          // this.$swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: "Account Kyc " + this.accountapproveKyc.status + " Succesfully ",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          this.loadadminKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    panKycApprovedSave() {
      this.$axios
        .post("staff/approveadminkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Pan Kyc " + this.panapproveKyc.status + " Succesfully ", {
            autoClose: 1000,
          });

          this.loadadminKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adminKycApprovedSave() {
      this.$axios
        .post("staff/approveadminkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.loadadminKyc();
          toast.success("admin Kyc " + this.approveKyc.status + " Succesfully", {
            autoClose: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adminlogofinder(admin) {
      var img;
      if (admin.logo != null) {
        img = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    adminkycValueApprove(data) {
      var value = data.admin;
      console.log(value);
      // alert('this is the admin id  adminkycValueAsign' + value.id)
      this.admin_id = value.id;
      this.approveKyc.admin_id = value.id;
      this.panapproveKyc.admin_id = value.id;
      this.aadharapproveKyc.admin_id = value.id;

      this.approveMyAdmin = value;

      // console.log(this.approveMyAdmin);
      if (value.businessPanPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.businessPanPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/" +
            value.businessPanPhoto;
        }
        this.businessPanPreImgPdf = this.businessPanPreImg;
        this.extensionBusinessPan = this.businessPanPreImg.split(".").pop();

        if (this.extensionBusinessPan == "pdf") {
          this.businessPanPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.businessPanPreImg = this.$store.state.placeholderImg;
      }

      if (value.gstinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.gstinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/" +
          value.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.gstinPhoto;
        }

        this.GstInPreImgPdf = this.gstinPreImg;
        this.extensionGstIn = this.gstinPreImg.split(".").pop();

        if (this.extensionGstIn == "pdf") {
          this.gstinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.gstinPreImg = this.$store.state.placeholderImg;
      }

      if (value.cinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.cinPhoto
        ) {
          this.cinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.cinPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto) {
          this.cinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.cinPhoto;
        }

        this.cinPreImgPdf = this.cinPreImg;
        this.extensionCin = this.cinPreImg.split(".").pop();

        if (this.extensionCin == "pdf") {
          this.cinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.cinPreImg = this.$store.state.placeholderImg;
      }

      if (value.coiPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.coiPhoto
        ) {
          this.coiPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.coiPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto) {
          this.coiPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.coiPhoto;
        }

        this.coiPreImgPdf = this.coiPreImg;
        this.extensionCoi = this.coiPreImg.split(".").pop();

        if (this.extensionCoi == "pdf") {
          this.coiPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.coiPreImg = this.$store.state.placeholderImg;
      }

      if (value.tdsPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin_id +
          "/thumbs/" +
          value.tdsPhoto
        ) {
          this.tdsPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin_id +
            "/thumbs/" +
            value.tdsPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto) {
          this.tdsPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin_id + "/" + value.tdsPhoto;
        }

        this.tdsPreImgPdf = this.tdsPreImg;
        this.extensionTds = this.tdsPreImg.split(".").pop();

        if (this.extensionTds == "pdf") {
          this.tdsPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.tdsPreImg = this.$store.state.placeholderImg;
      }

      this.panNoForKyc = data.panNo;
      this.accountNoForKyc = data.accountNo;
      this.aadharNoForKyc = data.aadharNo;

      this.form = data;
      if (data.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/admin/' + value.id + '/thumbs/' + data.panPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          data.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl + "/admin/" + value.id + "/thumbs/" + data.panPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" + value.id + "/" + data.panPhoto) {
          this.panImg =
            this.$store.state.imgUrl + "/admin/" + value.id + "/" + data.panPhoto;
        }

        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (data.aadharFrontPhoto != null) {
        // this.adharFrontImg = this.$store.state.imgUrl + '/admin/' + value.id + '/thumbs/' + data.aadharFrontPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          data.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/thumbs/" +
            data.aadharFrontPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/" +
          data.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl + "/admin/" + value.id + "/" + data.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (data.aadharBackPhoto != null) {
        // alert('adhar image')
        // this.adharBackImg = this.$store.state.imgUrl + '/admin/' + value.id + '/thumbs/' + value.admin_kyc.aadharBackPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/thumbs/" +
          data.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/admin/" +
            value.id +
            "/thumbs/" +
            data.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          value.id +
          "/" +
          data.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl + "/admin/" + value.id + "/" + data.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
    loadadminKyc() {
      this.loading = true;
      this.$axios
        .get(`staff/adminkyc`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.adminkyc = res.data.data.data;
        })

        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadadminKyc();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #fefeff;
  border-color: #f21003;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}
.table.table-sm th,
.table.table-sm td {
  padding: 0.2rem 0.5rem rem;
  /* font-weight: 200; */
}
</style>
